import { Article } from '@mui/icons-material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { routes } from '@redwoodjs/router'

import MenuItem from 'src/types/MenuItem'

// TODO: Below condition if there are new updates to display update icon on main menu
const areThereUpdates = true

const menuItems = (hasRole: (rolesToCheck: string | string[]) => boolean) => {
  const isStudent = hasRole('uq-student')

  const items: MenuItem[] = [
    {
      primaryText: 'Dashboard',
      secondaryText: 'My records, datasets and collaborators',
      linkTo: routes.dashboard(),
    },
    {
      primaryText: 'Create new record',
      secondaryText: 'For data management and research services',
      linkTo: routes.createRecord(),
    },
  ]

  if (hasRole('uq-student')) {
    items.push({
      primaryText: 'Thesis Dashboard',
      secondaryText: '',
      linkTo: routes.thesisDashboard(),
    })
  }

  if (hasRole('user-thesis-principal-advisor')) {
    items.push({
      primaryText: 'Thesis Advisor Dashboard',
      secondaryText: '',
      linkTo: routes.thesisAdvisorDashboard(),
    })
  }

  items.push(
    ...[
      {
        primaryText: 'About',
        secondaryText: '',
        linkTo: routes.about(),
      },
      {
        primaryText: 'UQRDM Resource Hub',
        secondaryText: 'User guides and FAQs',
        linkTo: routes.resourceHub(),
      },
      {
        linkTo: isStudent
          ? 'https://support.my.uq.edu.au/app/opa/research_support'
          : 'https://support.staff.uq.edu.au/app/opa/research_support',
        primaryText: 'Request support',
        openBlank: true,
        icon: <OpenInNewIcon fontSize="small" color="disabled" />,
      },
      {
        primaryText: 'UQRDM Updates',
        secondaryText: '',
        linkTo: routes.updates(),
        icon: <NotificationsActiveIcon fontSize="small" color={!areThereUpdates ? 'disabled' : 'primary'} />,
      },
      {
        primaryText: 'Feedback',
        secondaryText: 'Help us improve UQRDM',
        linkTo: routes.feedback(),
      },
      {
        primaryText: 'Logout',
        secondaryText: 'Sign out of UQRDM',
        linkTo: routes.logout(),
      },
    ]
  )

  if (hasRole('grad-school-dashboard')) {
    items.push({
      primaryText: 'Graduate School Dashboard',
      secondaryText: 'View and manage thesis submissions',
      linkTo: routes.graduateSchoolDashboard(),
      icon: <Article fontSize="small" color="disabled" />,
    })
  }

  if (hasRole('admin-dashboard')) {
    items.push({
      primaryText: 'Admin Dashboard',
      secondaryText: '',
      linkTo: routes.adminDashboard(),
      icon: <AdminPanelSettingsIcon fontSize="small" color="disabled" />,
    })
  }

  return items
}

export default menuItems
